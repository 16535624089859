import React, { useContext, useEffect, useState } from "react";
import "./groupBulkSearch.css";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  Loader,
  Message,
  Pagination,
  SelectPicker,
  useToaster,
  Modal
} from "rsuite";
import Form from "react-bootstrap/Form";
import { BiCopy, BiSortDown, BiSortUp } from "react-icons/bi";
import { AiOutlineExport } from "react-icons/ai";
import { Badge, Table } from "react-bootstrap";
import avatar_image from "../../../../assets/images/645fbc5da4a21.png";
import { MdFileDownload } from "react-icons/md";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";

const GroupBulkSearch = () => {
  const { selectedExtension } = useContext(ExtensionContext);
  const [selectedLocation, setSelectedLocation] = useState("Viet Nam");
  const [location, setLocation] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [listGroups, setListGroups] = useState([]);
  const [isStop, setIsStop] = useState(true);
  const [search, setSearch] = useState("");
  const [filterGroup, setFilterGroup] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [delays, setDelay] = useState(5);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [filterDownload, setFilterDownload] = useState([]);
  const [downloadModal, setDownloadModal] = useState(false);
  const { keepFieldsDownload } = useContext(DataContext);
  const [filterPrivacy, setFilterPrivacy] = React.useState([
    "Public Công khai",
    "Private Riêng tư",
  ]);
  const [sortingMember, setSortingMember] = useState("asc");

  useEffect(() => {
    setActivePage(1);
  }, [search]);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleSorting = (e) => {
    setSortingMember(e);

    if (e === "asc") {
      filterGroup.sort((a, b) => a.memberCount - b.memberCount);
    } else {
      filterGroup.sort((a, b) => b.memberCount - a.memberCount);
    }
  };

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      // console.log(message);
      switch (message?.params?.key_script) {
        case "get_locationID":
          const data = message?.data?.map((item) => ({
            label: item?.text,
            value: item?.id,
          }));
          setLocation(data);
          // setFilterValue(data[0]);
          setIsLoading(false);
          break;
        case "search_group":
          setListGroups(message.data);
          if (message?.done_all === true) {
            setIsStop(true);
          }
          break;
        case "force_stop":
          setIsStop(true);
        default:
          setIsStop(true);
          break;
      }
    });
  }, []);

  useEffect(() => {
    setActivePage(1);
  }, [limit]);

  useEffect(() => {
    const data = [...filterGroup, ...listGroups];
    const uniqueArray = data.filter(
      (item, index, self) =>
        index === self.findIndex((obj) => obj.id === item.id)
    );
    setFilterGroup(uniqueArray);
  }, [listGroups]);

  const getLocation = async () => {
    // console.log(uid);
    setIsLoading(true);
    const input = {
      key_script: "get_locationID",
      extension_key: selectedExtension?.key,
      key_tool: "pro_bulk_search_facebook_group",
      params: {
        key_socket: Cookies.get("keySocket"),
        message: {
          query: selectedLocation,
        },
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    setIsStop(false);
    setListGroups([]);
    setFilterGroup([]);
    setActivePage(1);
    let b = 0;
    let limit = 0;
    limit = keywords?.trim()?.split("\n")?.length;
    // if (pageNames?.split("\n")?.length < formValue?.limit) {
    //   limit = pageNames?.split("\n")?.length;
    // } else {
    //   limit = formValue?.limit;
    // }
    console.log(limit);
    if (keywords?.trim() !== "") {
      searchGroup(keywords?.split("\n")[b]);
      ++b;
      const id = setInterval(() => {
        if (b < limit) {
          searchGroup(keywords?.split("\n")[b]);
          b++;
        } else {
          clearInterval(id);
        }
      }, delays * 1000);
    } else {
      setIsStop(true);
      setTextMessage("Hãy điền từ khoá");
    }
  };

  const testInterval = () => {
    let b = 0;
    let limit = 0;
    limit = keywords?.split("\n")?.length;
    console.log(delays, "delay");
    const id = setInterval(() => {
      console.log(b);
      b++;
      if (b === 2) {
        clearInterval(id);
      }
    }, delays * 1000);
  };

  const searchGroup = async (keyword) => {
    // keywords?.split("\n")?.map(async (item) => {
    const input = {
      key_script: "search_group",
      extension_key: selectedExtension?.key,
      key_tool: "pro_bulk_search_facebook_group",
      params: {
        key_socket: Cookies.get("keySocket"),
        keyword: keyword,
        filterValues: [],
        // filterValues: filterValue !== undefined ? filterValue : "",
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsStop(false);
      }
    } catch (error) {
      console.log(error);
      setTextMessage("ERROR !");
      setIsStop(true);
    }
    // });
  };

  const handleStop = async () => {
    const input = {
      key_script: "force_stop",
      extension_key: selectedExtension?.key,
      key_tool: "force_stop",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   if (selectedExtension !== undefined) {
  //     getLocation();
  //   }
  // }, [selectedExtension]);

  return (
    <div className="">
      <Modal
        backdrop="static"
        role="alertdialog"
        keyboard={false}
        open={downloadModal}
        style={{ paddingTop: "5rem" }}
        onClose={() => setDownloadModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">Chọn các mục bạn muốn tải</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CheckboxGroup
            name="checkbox-group"
            value={filterDownload}
            onChange={value => {
              setFilterDownload(value);
            }}
          >
            <Checkbox value="id">ID</Checkbox>
            <Checkbox value="avatar">Ảnh</Checkbox>
            <Checkbox value="name">Tên</Checkbox>
            <Checkbox value="privacy">Trạng thái</Checkbox>
            <Checkbox value="member_count">Thành viên</Checkbox>
          </CheckboxGroup>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <CSVLink
            data={filterGroup
              ?.map((item) => ({
                ID: item?.id,
                Link: `https://www.facebook.com/${item?.id}`,
                NAME: item?.name,
                AVATAR: item?.avatar,
                KEYWORD: item?.keyword,
                PRIVACY: item?.privacy,
                MEMBER_COUNT: item?.memberCount,
                NUMBER_OF_POSTS: item?.numberOfPosts,
              }))
              ?.map(item => keepFieldsDownload(item, filterDownload))
            }
            filename="PRO_BULK_SEARCH_FACEBOOK_GROUP.csv"
            onClick={() => {
              if (!filterGroup?.length ) {
                setTextMessage("Không có dữ liệu để tải về");
                return false; // 👍🏻 You are stopping the handling of component
              }
            }}
          >
            <Button appearance="primary">
              <SiMicrosoftexcel className="me-2" />
              <span>Tải xuống</span>
            </Button>
          </CSVLink>
        </Modal.Footer>
      </Modal>
      <div>
        <div className="card p-3">
          <div>
            <div className="mb-3">
              <ToolHeading toolType={window.location.pathname} />
            </div>
            <div className="">
              <p className="group_membernumber">Từ khoá:</p>
              <Input
                as="textarea"
                rows={8}
                className="mb-2"
                placeholder="Từ khoá, mỗi từ 1 dòng"
                onChange={setKeywords}
              />
              {/* <Form.Select
                  size="sm"
                  style={{ width: "100%" }}
                  onChange={(e) => setFilterValue(e.target.value)}
                >
                  {isLoading ? <option>Loading...</option> : <></>}
                  {location?.map((item, key) => (
                    <option
                      key={key}
                      value={item?.label}
                      // style={{ color: "black" }}
                    >
                      {item?.label}
                    </option>
                  ))}
                </Form.Select> */}
              <div className="d-flex gap-2 mt-2">
                {/* <Form.Group className="col">
                    <Form.Label className="group_membernumber">
                      Threads :
                    </Form.Label>
                    <Form.Select size="sm">
                      <option>1</option>
                    </Form.Select>
                  </Form.Group> */}
                <Form.Group className="col">
                  <Form.Label className="group_membernumber">
                    Delay (giây) :
                  </Form.Label>
                  <Input value={delays} onChange={setDelay}></Input>
                </Form.Group>
                {/* <Form.Group className="col">
                    <Form.Label className="group_membernumber">
                      Limit per keyword :
                    </Form.Label>
                    <Form.Select size="sm">
                      <option>1</option>
                    </Form.Select>
                  </Form.Group> */}
              </div>
              {isStop === true ? (
                <Button
                  appearance="primary"
                  color="orange"
                  className="mt-2"
                  style={{ width: "100%" }}
                  // onClick={testInterval}
                  onClick={handleSearch}
                >
                  KHỞI ĐỘNG
                </Button>
              ) : (
                <Button
                  appearance="primary"
                  className="mt-2"
                  style={{ width: "100%", background: "red" }}
                  onClick={handleStop}
                >
                  DỪNG LẠI
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 card p-3">
        <div className="d-flex justify-content-between align-items-center">
          <Button 
            appearance="primary"
            style={{padding: "0.2rem 0.4rem"}}
            onClick={() => {
              setDownloadModal(true);
            }}
          >
            <MdFileDownload style={{fontSize: 21}}/>
          </Button>
          <div style={{ fontSize: ".8rem" }} className="fw-bold">
            Tổng group: {filterGroup?.length}
          </div>
          <div className="d-flex gap-2">
            <CheckboxGroup
              inline
              name="checkboxList"
              value={filterPrivacy}
              onChange={(value) => {
                console.log(value, "onChange");
                setFilterPrivacy(value);
                setActivePage(1);
              }}
              style={{ fontSize: ".8rem", whiteSpace: "nowrap" }}
            >
              <Checkbox value={"Public Công khai"}>Công khai</Checkbox>
              <Checkbox value={"Private Riêng tư"}>Riêng tư</Checkbox>
            </CheckboxGroup>
            <Input placeholder="Tìm kiếm" value={search} onChange={setSearch} />
            {/* <Input placeholder="Search" value={search} onChange={setSearch} /> */}
          </div>
        </div>
        <div className="mt-3">
          <Table borderless>
            <thead>
              <tr className="group_membernumber">
                <th style={{ width: "4rem" }}>#</th>
                <th>Tên</th>
                <th>Trạng thái</th>
                <th>
                  Thành viên{" "}
                  {sortingMember === "asc" ? (
                    <BiSortUp
                      className="sortingNumber"
                      onClick={() => {
                        handleSorting("des");
                      }}
                    />
                  ) : (
                    <BiSortDown
                      className="sortingNumber"
                      onClick={() => {
                        handleSorting("asc");
                      }}
                      // onClick={() => setSortingMember("des")}
                    />
                  )}
                </th>
              </tr>
              {!isStop ? (
                <tr>
                  <th colSpan={10}>
                    <LinearProgress style={{ width: "100%" }} />
                  </th>
                </tr>
              ) : (
                <></>
              )}
            </thead>
            <tbody>
              {/* {listGroups?.map((item, key) => ( */}
              {filterGroup
                ?.filter((item) =>
                  filterPrivacy?.some((privacy) => privacy.includes(item?.privacy))
                )
                ?.filter((item) =>
                  item?.name.toLowerCase().includes(search.toLowerCase())
                )

                ?.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td style={{ width: "4rem" }}>{key + 1}</td>
                      <td style={{ width: "50%" }}>
                        <div className="d-flex gap-3">
                          <div className="d-flex align-items-center">
                            <img
                              src={item?.avatar}
                              alt="avatar"
                              className="toolHeader__avatar"
                            />
                          </div>
                          <div>
                            <p className="group_membernumber fw-bold m-0">
                              <a
                                href={`https://www.facebook.com/${item?.id}`}
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item?.name}
                              </a>
                            </p>
                            <p className="group_membernumber m-0">{item?.id}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Badge
                          bg={
                            item?.privacy === "Public" || item?.privacy === "Công khai"
                              ? "primary"
                              : "secondary"
                          }
                        >
                          {item?.privacy}
                        </Badge>
                      </td>
                      <td>{item?.memberCount}</td>
                    </tr>
                  );
                })
                ?.splice((activePage - 1) * limit, limit)}
            </tbody>
          </Table>
          {filterGroup.length > 0 ? (
            <Pagination
              layout={["limit", "-", "pager"]}
              size={"xs"}
              prev
              next
              // first
              // last
              ellipsis
              boundaryLinks
              total={
                filterGroup
                  ?.filter((item) =>
                    filterPrivacy?.some((privacy) => privacy === item?.privacy)
                  )
                  ?.filter((item) =>
                    item?.name.toLowerCase().includes(search.toLowerCase())
                  )?.length
              }
              limit={limit}
              limitOptions={[10, 30, 50, 100, 500]}
              maxButtons={5}
              activePage={activePage}
              onChangePage={setActivePage}
              onChangeLimit={setLimit}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupBulkSearch;
